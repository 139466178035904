<template>
  <div>
    <SelectedItemsBar
      v-if="showSelectedItemBar"
      :selectedItems="selectedNodes"
      ref="selectBar"
      @clear-selection="clearSelection"
      :currentNode="$route.params.parentId"
    />

    <DefaultModal
      :title="$t('move_confirm_title')"
      ref="moveModal"
      @submit="callMoveMethod"
      @modal-closed="resetConfirmModal()"
      submit-button-text="move"
      :loading="modalLoading"
      :saveButtonDisabled="modalSaveButtonDisabled"
      :alert="modalAlert"
    >
      <template v-slot:content>
        <v-col class="text-body-1">
          <v-row>
           <p class="pre-line"><span class="font-weight-bold">From:</span> {{destination.from}}</p>
          </v-row>
          <v-row>
            <p><span class="font-weight-bold">To:</span> {{destination.to}}</p>
          </v-row>
          <v-row v-if="modalAlert" class="mt-4">
            <v-alert
              dense
              outlined
              :type="modalAlert.type"
            >
              {{modalAlert.message}}
            </v-alert>
          </v-row>
        </v-col>
      </template>
    </DefaultModal>

    <v-data-table
      @dblclick:row="interactRow"
      item-key="node_id"
      v-model="selectedNodes"
      :headers="headers"
      show-select
      :items="filteredEntries"
      :loading="loading"
      :items-per-page="-1"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :custom-sort="customSort"
      :single-select="false"
      class="elevation-1"
      disable-pagination
      hide-default-footer
      @toggle-select-all="selectAllToggle"
    >
      <template v-slot:item.data-table-select="{ item, isSelected, select }">
        <v-simple-checkbox
          :value="isSelected"
          :readonly="item.only_path"
          :disabled="item.only_path"
          @input="select($event)"
          :ripple="false"
        ></v-simple-checkbox>
      </template>

      <template v-slot:item.name="{ item }">
        <div
          @click="handleClick(item)"
          :draggable="true"
          @drop="onDrop"
          @dragstart="dragStart(item)"
          @dragover="dragOver($event, item)"
          @dragenter="dragEnter($event, item)"
          @dragleave="dragLeave()"
          @dragend="dragEnd()"
          :class="{'drag-over': droppedNode != null && item.node_id == droppedNode.node_id && dragInternal}"
          class="d-flex align-center"
        >
          <div
            v-if="item.locked && item.type === 'file'"
            class="d-flex align-center"
          >
            <v-progress-circular
              class="mr-2"
              indeterminate
              color="primary"
              size="22"
              width="3"
            ></v-progress-circular>
            <p class="font-weight-bold text-no-wrap my-0 mx-2">
              Uploading<span class="dots">.</span><span class="dots">.</span><span class="dots">.</span>
            </p>
          </div>
          <v-icon
            v-else
            :set="icon = getItemIcon(item)"
            :color="icon.color || 'defaultIconColor'"
            class="mr-3"
          >{{ icon.icon }}</v-icon>
          {{ item.name }}
        </div>
      </template>

      <template v-slot:item.created_at="{ item }">
        {{ formatDate(item.created_at) }}
      </template>

      <template v-slot:item.updated_at="{ item }">
        {{ formatDate(item.updated_at) }}
      </template>

      <template v-slot:item.filesize="{ item }">
        {{ formatSize(item.filesize) }}
      </template>

      <template v-slot:item.users_id="{ item }">
        <UserInfoColumn
          :user="getNodeUserInfo(item)"
        />
      </template>
      <template v-slot:item.actions="{ item }">
        <ActionsColumn
          :item="item"
          ref="actionsMenu"
        />
      </template>
    </v-data-table>
  </div>
</template>

<style scoped>
  .drag-over {
    border: 2px dashed var(--v-primary-base);
    border-radius: 4px;
    padding: 6px;
    background-color: var(--v-primary-lighten5);
  }

  .dots {
    display: inline-block;
    animation: bounce 1.5s infinite ease-in-out;
    padding: 0 1px;
  }

  .dots:nth-child(2) {
    animation-delay: 0.2s;
  }

  .dots:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-6px);
    }
  }
</style>

<script>

import _ from 'lodash'
import { mapActions, mapState } from 'vuex'
import router from '@/router'

import HumanReadable from "@/utils/human_readable"
import IconsByMimetype from '@/utils/iconsByMimetype.js'
import UpdateBrowserTab from '@/utils/updateBrowserTab'
import ActionsColumn from '@/components/content/navigation/list/ActionsColumn'
import UserInfoColumn from '@/components/content/navigation/list/UserInfoColumn'
import SelectedItemsBar from '@/components/content/SelectedItemsBar.vue'
import DefaultModal from '@/components/DefaultModal.vue'
import AppActions from '@/store/app/actions-types'
import ContentActions from '@/store/content/actions/actions-types'
import EntryActions from '@/store/content/entry/actions-types'

export default {
  name: "NavigationListView",

  components: {
    DefaultModal,
    UserInfoColumn,
    ActionsColumn,
    SelectedItemsBar,
  },

  props: {
    loading: {
      type: Boolean,
      default: false
    },
    entries: {
      type: Array,
      default: () => []
    },
    users: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      sortBy: [],
      sortDesc: [],
      selectedNodes: [],
      disabledCount: 0,
      droppedNode: null,
      dragInternal: false,
      sourceNodeList: [],
      destinationNode: null,
      destination: {},
      modalLoading: true,
      modalSaveButtonDisabled: true,
      modalAlert: null,
    }
  },

  computed: {
    headers() {
      return [
        {text: this.$t('name'), value: 'name'},
        {text: this.$t('creation_date'), value: 'created_at'},
        {text: this.$t('last_modification'), value: 'updated_at'},
        {text: this.$t('size'), value: 'filesize'},
        {text: this.$t('created_by'), value: 'users_id'},
        {text: this.$t('actions'), value: 'actions', sortable: false, align: 'center'},
      ]
    },

    showSelectedItemBar() {
      return this.selectedNodes.length > 0
    },

    filteredEntries() {
      return this.entries.filter(e => ! /^\..*/.test(e.name))
    },

    ...mapState({
      mappedEditActions: state => state.content.actions.editActionByExtension,
    }),
  },

  methods: {
    ...mapActions('app', [
      AppActions.OPEN_APP_SUCCESS_MESSAGE,
      AppActions.OPEN_APP_ERROR_MESSAGE,
    ]),

    ...mapActions('content/actions', [
      ContentActions.MOVE,
      ContentActions.MOVE_ENTRIES,
      ContentActions.GET_NODE_ACTIONS,
    ]),

    ...mapActions('content/entry', [
      EntryActions.CHECK_COPY_MOVE_POSSIBILITY,
    ]),

    interactRow({}, {item}) {
      if (item.type == "folder") {
        this.$emit('enter-folder', item)
      }
    },

    customSort() {
      const sortFields = ['type', ...this.sortBy]
      const sortDesc = [true, ...this.sortDesc]

      const sortOrders = _.map(sortDesc, (item) => (!item ? 'asc' : 'desc'))

      return _.orderBy(this.filteredEntries, sortFields, sortOrders)
    },

    getItemIcon(item) {
      let icon = IconsByMimetype.getIconByMimetype(item.extension, item.mime)

      if (item.type == "folder") {
        icon = { icon: 'mdi-folder', color: 'defaultIconColor' }

        if (item.only_path) {
          icon.icon = 'mdi-folder-outline'

          return icon
        }
      }

      if (item.style_color) {
        icon.color = item.style_color
      }

      return icon
    },

    formatDate(date) {
      return HumanReadable.date(date)
    },

    formatSize(size) {
      if (size == null || size < 0) {
        return "—"
      }

      return HumanReadable.fileSize(size, true)
    },

    getNodeUserInfo(item) {
      return this.users.find(user => user.users_id == item.users_id)
    },

    clearSelection()  {
      this.selectAllToggle({'items': this.entries, 'value': false})
    },

    selectAllToggle(props) {
      if(this.selectedNodes.length != this.entries.length - this.disabledCount) {
        this.selectedNodes = [];
        const self = this;
        props.items.forEach(item => {
          if(!item.only_path && props.value == true) {
            self.selectedNodes.push(item);
          }
        });
      } else this.selectedNodes = [];
    },

    dragStart(item) {
      parent.dragInternal = true
      this.dragInternal = true
      parent.droppedNode = null
      parent.draggedList = [item]
      this.selectedNodes.forEach(node => {
        if (node.node_id == item.node_id) {
          parent.draggedList = this.selectedNodes
          return true
        }
      })
      return true
    },

    dragOver(event, item) {
      this.dragInternal = parent.dragInternal;
      if (this.dragInternal && item.type == "folder") {
        parent.droppedNode = item
        this.droppedNode = item
        event.preventDefault()
      } else {
        parent.droppedNode = null
        this.droppedNode = null
      }
    },

    dragEnter(event, item) {
      this.dragOver(event, item)
    },

    dragEnd() {
      parent.dragInternal = false
      this.dragInternal = false
      parent.droppedNode = null
      this.droppedNode = null
    },

    dragLeave() {
      parent.droppedNode = null
      this.droppedNode = null
    },

    onDrop() {
      var internal = parent.dragInternal
      parent.dragInternal = false
      this.dropFileDragOver = false

      if (parent.droppedNode.node_id > 0 && internal) {
        var valid = true;
        parent.draggedList.forEach(item => {
          if (item.node_id == parent.droppedNode.node_id) {
            valid = false
          }
        })
        if (valid) {
          this.sourceNodeList = parent.draggedList
          this.destinationNode = parent.droppedNode
          this.confirmModal()
        }
        return
      }
    },

    confirmModal() {
      const sourceNodeList = this.sourceNodeList
      const destinationNode = this.destinationNode

      this.destination.from = '\n' + sourceNodeList.map(i => i.entry_fs_id).join(',\n');

      this.destination.to = destinationNode.node_parent === null ? `/${destinationNode.name}` : destinationNode.entry_fs_id;

      this.resetConfirmModal()
      this.$refs.moveModal.openModal()

      let nodesIds = sourceNodeList.map(item => item.node_id)

      let canContinue = true

      const data = {"destination_id": destinationNode.node_id, "action_key": "move", 'nodes_ids': nodesIds}

      this[EntryActions.CHECK_COPY_MOVE_POSSIBILITY](data)
        .then(response => {
            this.modalLoading = false;
            response.forEach(res => {
              if (res.node_name_already_exists) {
                canContinue = false;
                this.modalAlert = { type: "error", message: this.$t('move_confirm_alert') }
              }
            })
        })
        .catch(() => this.loading = false)
        .finally(() => this.modalSaveButtonDisabled = !canContinue)
    },

    resetConfirmModal() {
      this.modalLoading = true;
      this.modalSaveButtonDisabled = true;
      this.modalAlert = null;
    },

    callMoveMethod() {
      this.$refs.moveModal.closeModal()

      let nodesIds = this.sourceNodeList.map(item => item.node_id)
      let params = {'entries': nodesIds, 'destination_id': this.destinationNode.node_id}

      this[ContentActions.MOVE_ENTRIES](params)
        .then(() => {
          this.selectedNodes = []
        })
        .catch((error) => {
          this[AppActions.OPEN_APP_ERROR_MESSAGE](this.$t(`move_failed`))
          this.$emit(`move-error`, error)
        })
    },

    handleClick(node) {
      this.clickCount++

      setTimeout(() => {
        if (this.clickCount === 1) {
          this.openPreviewModal(node)
        } else if (this.clickCount === 2) {
          this.edit(node)
        }
        this.clickCount = 0
      }, 250)
    },
    
    openPreviewModal(node) {
      if(node.type != "folder" && node.locked == false) {
        this.$refs.actionsMenu.openPreviewModal()
      }
    },

    edit(node) {
      if (node.type == "folder" || node.locked == true) {
        return
      }

      if (node.loadedActions) {
        this.genericEdit(node)

        return
      }

      document.body.style.cursor = "wait"

      this[ContentActions.GET_NODE_ACTIONS](node.node_id)
        .then((result) => {
          node.nodeActions = [...result]
          node.loadedActions = true

          this.genericEdit(node)
        })
        .finally(() => {
          document.body.style.cursor = ""
        })
    },
    
    genericEdit(node) {
      const editAction = this.mappedEditActions.find(action => action.extensions.includes(node.extension))

      const action = node.nodeActions.find(action => action.action_key === editAction?.actionKey)

      if (action) {
        this[editAction.callMethod](node)
      }
    },

    editGDocAction(node) {
      let routeData = router.resolve({name: 'EditGDoc', params: { appId: this.$route.params.appId, nodeId: node.node_id }, query: { from: this.$route.fullPath } });
      let newWindow = window.open(routeData.href, '_blank');
      let icon = IconsByMimetype.getIconByMimetype(node.extension, node.mime).icon

      newWindow.addEventListener('load', () => {
        UpdateBrowserTab.update(newWindow, node.name, icon)
      })
    },

    editSyntheticMediaAction(node) {
      let routeData = router.resolve({name: 'EditSyntheticMedia',  params: { appId: this.$route.params.appId, nodeId: node.node_id }});
      let newWindow = window.open(routeData.href, '_blank')

      newWindow.addEventListener('load', () => {
        UpdateBrowserTab.update(newWindow, node.name, 'mdi-star-four-points-box')
      });
    },

    editJstAction(node) {
      let routeData = router.resolve({name: 'EditJst', params: { appId: this.$route.params.appId, nodeId: node.node_id }, query: { from: this.$route.fullPath } });
      let newWindow = window.open(routeData.href, '_blank')

      newWindow.addEventListener('load', () => {
        UpdateBrowserTab.update(newWindow, node.name)
      });
    },
  },

  created() {
    const self = this;
    this.entries.map(item => {
      if (item.only_path) self.disabledCount += 1
    })
  }
}

</script>
