var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showSelectedItemBar)?_c('SelectedItemsBar',{ref:"selectBar",attrs:{"selectedItems":_vm.selectedNodes,"currentNode":_vm.$route.params.parentId},on:{"clear-selection":_vm.clearSelection}}):_vm._e(),_c('DefaultModal',{ref:"moveModal",attrs:{"title":_vm.$t('move_confirm_title'),"submit-button-text":"move","loading":_vm.modalLoading,"saveButtonDisabled":_vm.modalSaveButtonDisabled,"alert":_vm.modalAlert},on:{"submit":_vm.callMoveMethod,"modal-closed":function($event){return _vm.resetConfirmModal()}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-col',{staticClass:"text-body-1"},[_c('v-row',[_c('p',{staticClass:"pre-line"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("From:")]),_vm._v(" "+_vm._s(_vm.destination.from))])]),_c('v-row',[_c('p',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("To:")]),_vm._v(" "+_vm._s(_vm.destination.to))])]),(_vm.modalAlert)?_c('v-row',{staticClass:"mt-4"},[_c('v-alert',{attrs:{"dense":"","outlined":"","type":_vm.modalAlert.type}},[_vm._v(" "+_vm._s(_vm.modalAlert.message)+" ")])],1):_vm._e()],1)]},proxy:true}])}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"item-key":"node_id","headers":_vm.headers,"show-select":"","items":_vm.filteredEntries,"loading":_vm.loading,"items-per-page":-1,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"custom-sort":_vm.customSort,"single-select":false,"disable-pagination":"","hide-default-footer":""},on:{"dblclick:row":_vm.interactRow,"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"toggle-select-all":_vm.selectAllToggle},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"value":isSelected,"readonly":item.only_path,"disabled":item.only_path,"ripple":false},on:{"input":function($event){return select($event)}}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center",class:{'drag-over': _vm.droppedNode != null && item.node_id == _vm.droppedNode.node_id && _vm.dragInternal},attrs:{"draggable":true},on:{"click":function($event){return _vm.handleClick(item)},"drop":_vm.onDrop,"dragstart":function($event){return _vm.dragStart(item)},"dragover":function($event){return _vm.dragOver($event, item)},"dragenter":function($event){return _vm.dragEnter($event, item)},"dragleave":function($event){return _vm.dragLeave()},"dragend":function($event){return _vm.dragEnd()}}},[(item.locked && item.type === 'file')?_c('div',{staticClass:"d-flex align-center"},[_c('v-progress-circular',{staticClass:"mr-2",attrs:{"indeterminate":"","color":"primary","size":"22","width":"3"}}),_c('p',{staticClass:"font-weight-bold text-no-wrap my-0 mx-2"},[_vm._v(" Uploading"),_c('span',{staticClass:"dots"},[_vm._v(".")]),_c('span',{staticClass:"dots"},[_vm._v(".")]),_c('span',{staticClass:"dots"},[_vm._v(".")])])],1):_c('v-icon',{staticClass:"mr-3",attrs:{"set":_vm.icon = _vm.getItemIcon(item),"color":_vm.icon.color || 'defaultIconColor'}},[_vm._v(_vm._s(_vm.icon.icon))]),_vm._v(" "+_vm._s(item.name)+" ")],1)]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.updated_at))+" ")]}},{key:"item.filesize",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatSize(item.filesize))+" ")]}},{key:"item.users_id",fn:function(ref){
var item = ref.item;
return [_c('UserInfoColumn',{attrs:{"user":_vm.getNodeUserInfo(item)}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('ActionsColumn',{ref:"actionsMenu",attrs:{"item":item}})]}}]),model:{value:(_vm.selectedNodes),callback:function ($$v) {_vm.selectedNodes=$$v},expression:"selectedNodes"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }